define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflow extends _discourse.default {
    async model() {
      return this.store.findAll("workflow");
    }
    titleToken() {
      return (0, _discourseI18n.i18n)("admin.discourse_workflow.workflows.title");
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflow;
});