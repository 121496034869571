define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-edit", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowEdit extends _discourse.default {
    async model(params) {
      const allWorkflows = await this.modelFor("adminPlugins.show.discourse-workflow-workflows").content;
      const id = parseInt(params.workflow_id, 10);
      // return allWorkflows.find((workflow) => workflow.id === id);
      return allWorkflows.findBy("id", id);
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("allWorkflows", this.modelFor("adminPlugins.show.discourse-workflow-workflows"));
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowEdit;
});