define("discourse/plugins/discourse-workflow/discourse/connectors/topic-list-after-badges/workflow-link", ["exports", "@glimmer/component", "discourse-i18n", "discourse/plugins/discourse-workflow/discourse/components/workflow-name-link", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _workflowNameLink, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-empty-glimmer-component-classes */

  class WorkflowLink extends _component.default {
    get label() {
      return (0, _discourseI18n.i18n)("discourse_workflow.workflow_link", {
        workflow_name: this.args.outletArgs.topic.workflow_name,
        workflow_step_name: this.args.outletArgs.topic.workflow_step_name
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.topic.workflow_name}}
          <span class="workflow-after-title">
            <WorkflowNameLink
              @topic_id={{@outletArgs.topic.id}}
              @workflow_name={{@outletArgs.topic.workflow_name}}
              @label={{this.label}}
              @icon="right-left"
            />
          </span>
        {{/if}}
      
    */
    {
      "id": "F7phTRhl",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"topic\",\"workflow_name\"]],[[[1,\"      \"],[10,1],[14,0,\"workflow-after-title\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@topic_id\",\"@workflow_name\",\"@label\",\"@icon\"],[[30,1,[\"topic\",\"id\"]],[30,1,[\"topic\",\"workflow_name\"]],[30,0,[\"label\"]],\"right-left\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/connectors/topic-list-after-badges/workflow-link.js",
      "scope": () => [_workflowNameLink.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WorkflowLink;
});