define("discourse/plugins/discourse-workflow/discourse/components/topic-list/header/workflow-name-cell", ["exports", "discourse/plugins/discourse-workflow/discourse/components/topic-list/header/sortable-column", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _sortableColumn, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WorkflowNameCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SortableColumn
      @sortable={{@sortable}}
      @number="false"
      @order="workflow-name"
      @activeOrder={{@activeOrder}}
      @changeSort={{@changeSort}}
      @ascending={{@ascending}}
      @name="workflow-name"
    />
  
  */
  {
    "id": "QnJCJA3V",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@sortable\",\"@number\",\"@order\",\"@activeOrder\",\"@changeSort\",\"@ascending\",\"@name\"],[[30,1],\"false\",\"workflow-name\",[30,2],[30,3],[30,4],\"workflow-name\"]],null],[1,\"\\n\"]],[\"@sortable\",\"@activeOrder\",\"@changeSort\",\"@ascending\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/components/topic-list/header/workflow-name-cell.js",
    "scope": () => [_sortableColumn.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "workflow-name-cell:WorkflowNameCell"));
  var _default = _exports.default = WorkflowNameCell;
});