define("discourse/plugins/discourse-workflow/discourse/components/user-menu/workflow-notifications-list-empty-state", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="empty-state">
    <span class="empty-state-title">
      {{i18n "user_menu.no_workflow_notifications_title"}}
    </span>
    <div class="empty-state-body">
      <p>
        {{html-safe
          (i18n
            "user_menu.no_workflow_notifications_body"
            preferencesUrl=(get-url "/my/preferences/notifications")
          )
        }}
      </p>
    </div>
  </div>
  */
  {
    "id": "00JxXf8v",
    "block": "[[[10,0],[14,0,\"empty-state\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"empty-state-title\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"user_menu.no_workflow_notifications_title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"empty-state-body\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,4],[[28,[37,2],[\"user_menu.no_workflow_notifications_body\"],[[\"preferencesUrl\"],[[28,[37,5],[\"/my/preferences/notifications\"],null]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"span\",\"i18n\",\"p\",\"html-safe\",\"get-url\"]]",
    "moduleName": "discourse/plugins/discourse-workflow/discourse/components/user-menu/workflow-notifications-list-empty-state.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "workflow-notifications-list-empty-state"));
});