define("discourse/plugins/discourse-workflow/discourse/admin/components/workflow-back-button", ["exports", "@ember/routing", "truth-helpers", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _routing, _truthHelpers, _dIcon, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <LinkTo class="btn btn-flat back-button" @route={{@route}} @model={{@model}}>
      {{dIcon "chevron-left"}}
      {{i18n (or @label "back_button")}}
    </LinkTo>
  
  */
  {
    "id": "QG2XIUUG",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn btn-flat back-button\"]],[[\"@route\",\"@model\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[32,1],[\"chevron-left\"],null]],[1,\"\\n    \"],[1,[28,[32,2],[[28,[32,3],[[30,3],\"back_button\"],null]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@route\",\"@model\",\"@label\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/admin/components/workflow-back-button.js",
    "scope": () => [_routing.LinkTo, _dIcon.default, _discourseI18n.i18n, _truthHelpers.or],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "workflow-back-button"));
});