define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-steps-options", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowStepOptions extends _discourse.default {
    async model(params) {
      const workflowSteps = this.modelFor("admin.plugins.show.discourse-workflow.workflows.steps");
      const id = parseInt(params.step_id, 10);
      const workflowStep = workflowSteps.findBy("id", id);
      const workflow_id = workflowStep.workflow_id;
      const allWorkflowStepOptions = await this.store.findAll("workflow-step-option", {
        workflow_step_id: workflowStep.id,
        workflow_id
      }); // this.modelFor("adminPlugins.show.discourse-workflow-workflows");
      return allWorkflowStepOptions.content;
    }
    titleToken() {
      return (0, _discourseI18n.i18n)("admin.discourse_workflow.workflows.title");
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowStepOptions;
});