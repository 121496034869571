define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-steps", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowSteps extends _discourse.default {
    async model(params) {
      const allWorkflowSteps = await this.store.findAll("workflow-step", {
        workflow_id: params.workflow_id
      }); // this.modelFor("adminPlugins.show.discourse-workflow-workflows");
      return allWorkflowSteps.content;
    }
    titleToken() {
      return (0, _discourseI18n.i18n)("admin.discourse_workflow.workflows.title");
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowSteps;
});