define("discourse/plugins/discourse-workflow/discourse/admin/models/workflow-step-option", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CREATE_ATTRIBUTES = ["workflow_step_id", "workflow_option_id", "position", "target_step_id"];
  class WorkflowStepOption extends _rest.default {
    updateProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      attrs.id = this.id;
      return attrs;
    }
    createProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      return attrs;
    }
    workingCopy() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      const workflowStepOption = WorkflowStepOption.create(attrs);
      return workflowStepOption;
    }
  }
  _exports.default = WorkflowStepOption;
});