define("discourse/plugins/discourse-workflow/discourse/admin/adapters/workflow-step", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Adapter extends _rest.default {
    jsonMode = true;
    basePath(store, type, findArgs) {
      if (findArgs) {
        if (typeof findArgs === "object") {
          if (findArgs) {
            return `/admin/plugins/discourse-workflow/workflows/${findArgs.workflow_id}/`;
          }
        } else {
          return `/admin/plugins/discourse-workflow/`;
        }
      } else {
        return "/admin/plugins/discourse-workflow/";
      }
    }
    pathFor(store, type, findArgs) {
      // removes underscores which are implemented in base
      let path = this.basePath(store, type, findArgs) + store.pluralize(this.apiNameFor(type));
      return this.appendQueryParams(path, findArgs);
    }
    apiNameFor() {
      return "workflow_step";
    }
  }
  _exports.default = Adapter;
});