define("discourse/plugins/discourse-workflow/discourse/templates/connectors/topic-title/topic-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <WorkflowTopicBanner
    @workflow_step_options={{this.model.workflow_step_options}}
    @workflow_step_position={{this.model.workflow_step_position}}
    @workflow_step_name={{this.model.workflow_step_name}}
    @workflow_name={{this.model.workflow_name}}
    @topic_id={{this.model.id}}
    @category_id={{this.model.category_id}}
  />
  */
  {
    "id": "fxcvOW0r",
    "block": "[[[8,[39,0],null,[[\"@workflow_step_options\",\"@workflow_step_position\",\"@workflow_step_name\",\"@workflow_name\",\"@topic_id\",\"@category_id\"],[[30,0,[\"model\",\"workflow_step_options\"]],[30,0,[\"model\",\"workflow_step_position\"]],[30,0,[\"model\",\"workflow_step_name\"]],[30,0,[\"model\",\"workflow_name\"]],[30,0,[\"model\",\"id\"]],[30,0,[\"model\",\"category_id\"]]]],null]],[],false,[\"workflow-topic-banner\"]]",
    "moduleName": "discourse/plugins/discourse-workflow/discourse/templates/connectors/topic-title/topic-title.hbs",
    "isStrictMode": false
  });
});