define("discourse/plugins/discourse-workflow/discourse/components/workflow-visualisation-modal", ["exports", "@ember/component", "discourse/components/d-modal", "discourse-i18n", "discourse/plugins/discourse-workflow/discourse/components/workflow-visualisation", "@ember/template-factory"], function (_exports, _component, _dModal, _discourseI18n, _workflowVisualisation, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WorkflowVisualisationModalComponent extends _component.default {
    get title() {
      return (0, _discourseI18n.i18n)("discourse_workflow.topic_banner.visualisation_title", {
        workflow_name: this.model.workflow_name
      });
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{this.title}}
          @closeModal={{@closeModal}}
          class="workflow-visualisation-modal"
        >
          <WorkflowVisualisation @model={{@model}} />
        </DModal>
      
    */
    {
      "id": "PR7OF6kC",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"workflow-visualisation-modal\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"title\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],null,[[\"@model\"],[[30,2]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/components/workflow-visualisation-modal.js",
      "scope": () => [_dModal.default, _workflowVisualisation.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WorkflowVisualisationModalComponent;
});