define("discourse/plugins/discourse-workflow/discourse/admin/models/workflow", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CREATE_ATTRIBUTES = ["name", "description", "enabled"];
  class Workflow extends _rest.default {
    updateProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      attrs.id = this.id;
      return attrs;
    }
    createProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      return attrs;
    }
    workingCopy() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      const workflow = Workflow.create(attrs);
      return workflow;
    }
  }
  _exports.default = Workflow;
});