define("discourse/plugins/discourse-workflow/discourse/initializers/workflow-user-menu", ["exports", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-i18n"], function (_exports, _pluginApi, _utilities, _getUrl, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "workflow-user-menu",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        if (api.registerNotificationTypeRenderer) {
          api.registerNotificationTypeRenderer("workflow_topic_arrival", NotificationItemBase => {
            return class extends NotificationItemBase {
              icon = "right-left";
              linkTitle = (() => (0, _discourseI18n.i18n)("notifications.titles.workflow_topic_arrival", {
                username: (0, _utilities.formatUsername)(this.notification.data.username),
                topic_title: this.notification.data.topic_title,
                workflow_name: this.notification.data.workflow_name,
                workflow_step_name: this.notification.data.workflow_step_name
              }))();
              description = (() => (0, _discourseI18n.i18n)("notifications.workflow_topic_arrival_description", {
                username: (0, _utilities.formatUsername)(this.notification.data.username),
                topic_title: this.notification.data.topic_title,
                workflow_name: this.notification.data.workflow_name,
                workflow_step_name: this.notification.data.workflow_step_name
              }))();
              get label() {
                const data = this.notification.data;
                return (0, _discourseI18n.i18n)("notifications.workflow_topic_arrival_label", {
                  username: (0, _utilities.formatUsername)(data.username),
                  topic_title: data.topic_title,
                  workflow_name: data.workflow_name,
                  workflow_step_name: data.workflow_step_name
                });
              }
              get linkHref() {
                const data = this.notification.data;
                return (0, _getUrl.default)(`/t/${data.topic_id}`);
              }
            };
          });
        }
        if (api.registerUserMenuTab) {
          api.registerUserMenuTab(UserMenuTab => {
            return class extends UserMenuTab {
              get id() {
                return "workflow-notifications";
              }
              get panelComponent() {
                return "user-menu/workflow-notifications-list";
              }
              get icon() {
                return "right-left";
              }
              get count() {
                return this.getUnreadCountForType("workflow_topic_arrival");
              }
              get notificationTypes() {
                return ["workflow_topic_arrival"];
              }
            };
          });
        }
      });
    }
  };
});