define("discourse/plugins/discourse-workflow/discourse/components/workflow-buttons", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _ajax, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WorkflowButtonsComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    workflowActionLabel = option => {
      return `discourse_workflow.options.${option}.button_label`;
    };
    actOnWorkflow(option) {
      this.dialog.yesNoConfirm({
        message: (0, _discourseI18n.i18n)(`discourse_workflow.options.${option}.confirmation`),
        didConfirm: () => {
          (0, _ajax.ajax)(`/discourse-workflow/act/${this.args.topic_id}`, {
            type: "POST",
            data: {
              option
            }
          }).then(() => {
            this.router.transitionTo("/c/" + this.args.category_id);
          }).catch(err => {
            (0, _ajaxError.popupAjaxError)(err);
          });
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "actOnWorkflow", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="workflow-banner-title workflow-buttons-title">
          {{i18n "discourse_workflow.topic_banner.actions_intro"}}
        </div>
        <div class="workflow-action-buttons">
          {{#each @workflow_step_options as |option|}}
            <div class="workflow-action-button">
              <DButton
                class="btn-primary"
                @action={{fn this.actOnWorkflow option}}
                @label={{this.workflowActionLabel option}}
              />
            </div>
          {{/each}}
        </div>
      
    */
    {
      "id": "fsCedwBO",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"workflow-banner-title workflow-buttons-title\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_workflow.topic_banner.actions_intro\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"workflow-action-buttons\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"workflow-action-button\"],[12],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[32,2],[[30,0,[\"actOnWorkflow\"]],[30,2]],null],[28,[30,0,[\"workflowActionLabel\"]],[[30,2]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@workflow_step_options\",\"option\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/components/workflow-buttons.js",
      "scope": () => [_discourseI18n.i18n, _dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WorkflowButtonsComponent;
});