define("discourse/plugins/discourse-workflow/discourse/admin/models/workflow-step", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CREATE_ATTRIBUTES = ["workflow_id", "position", "name", "category_id", "description", "ai_enabled", "ai_prompt"];
  class WorkflowStep extends _rest.default {
    static async findAllForWorkflow(workflowId) {
      const result = await (0, _ajax.ajax)(`discourse_workflow/workflow/${workflowId}/workflow_step.json`);
      return result.workflow_steps;
    }
    updateProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      attrs.id = this.id;
      return attrs;
    }
    createProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      return attrs;
    }
    workingCopy() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      const workflowStep = WorkflowStep.create(attrs);
      return workflowStep;
    }
  }
  _exports.default = WorkflowStep;
});