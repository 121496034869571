define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-steps-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowStepsNew extends _discourse.default {
    async model() {
      // Get the parent workflow
      const workflow = this.modelFor("adminPlugins.show.discourse-workflow-workflows-steps");
      // Create a new workflow step record
      const record = this.store.createRecord("workflow-step", {
        workflow_id: workflow.id,
        position: workflow.workflow_steps.length > 0 ? workflow.workflow_steps[workflow.workflow_steps.length - 1].position + 1 : 1
      });

      // Attach it to the parent workflow to current step
      record.set("workflow", workflow);
      return record;
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowStepsNew;
});