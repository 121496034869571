define("discourse/plugins/discourse-workflow/discourse/initializers/init-workflow", ["exports", "discourse/components/topic-list/header/sortable-column", "discourse/lib/plugin-api", "discourse/plugins/discourse-workflow/discourse/components/workflow-name-link", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _sortableColumn, _pluginApi, _workflowNameLink, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WORKFLOW_LIST_ROUTES = ["discovery.workflow"];
  const workflowNameHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SortableColumn
      @sortable={{@sortable}}
      @number="false"
      @order="workflow-name"
      @activeOrder={{@activeOrder}}
      @changeSort={{@changeSort}}
      @ascending={{@ascending}}
      @name="workflow-name"
    />
  
  */
  {
    "id": "iKtVSxYb",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@sortable\",\"@number\",\"@order\",\"@activeOrder\",\"@changeSort\",\"@ascending\",\"@name\"],[[30,1],\"false\",\"workflow-name\",[30,2],[30,3],[30,4],\"workflow-name\"]],null],[1,\"\\n\"]],[\"@sortable\",\"@activeOrder\",\"@changeSort\",\"@ascending\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/initializers/init-workflow.js",
    "scope": () => [_sortableColumn.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "init-workflow:workflowNameHeader"));
  const workflowNameCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="workflow-name">
      <WorkflowNameLink
        @topic_id={{@topic.id}}
        @workflow_name={{@topic.workflow_name}}
        @label={{@topic.workflow_name}}
      />
    </td>
  
  */
  {
    "id": "Vo9x7168",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"workflow-name\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic_id\",\"@workflow_name\",\"@label\"],[[30,1,[\"id\"]],[30,1,[\"workflow_name\"]],[30,1,[\"workflow_name\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/initializers/init-workflow.js",
    "scope": () => [_workflowNameLink.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "init-workflow:workflowNameCell"));
  const workflowStepPositionHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SortableColumn
      @sortable={{@sortable}}
      @number="true"
      @order="workflow-step-position"
      @activeOrder={{@activeOrder}}
      @changeSort={{@changeSort}}
      @ascending={{@ascending}}
      @name="workflow-step-position"
    />
  
  */
  {
    "id": "uiqbI1/p",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@sortable\",\"@number\",\"@order\",\"@activeOrder\",\"@changeSort\",\"@ascending\",\"@name\"],[[30,1],\"true\",\"workflow-step-position\",[30,2],[30,3],[30,4],\"workflow-step-position\"]],null],[1,\"\\n\"]],[\"@sortable\",\"@activeOrder\",\"@changeSort\",\"@ascending\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/initializers/init-workflow.js",
    "scope": () => [_sortableColumn.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "init-workflow:workflowStepPositionHeader"));
  const workflowStepPositionCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="workflow-step-position">
      <WorkflowNameLink
        @topic_id={{@topic.id}}
        @workflow_name={{@topic.workflow_name}}
        @label={{@topic.workflow_step_position}}
      />
    </td>
  
  */
  {
    "id": "MB+f4S4n",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"workflow-step-position\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic_id\",\"@workflow_name\",\"@label\"],[[30,1,[\"id\"]],[30,1,[\"workflow_name\"]],[30,1,[\"workflow_step_position\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/initializers/init-workflow.js",
    "scope": () => [_workflowNameLink.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "init-workflow:workflowStepPositionCell"));
  const workflowStepNameHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SortableColumn
      @sortable={{@sortable}}
      @number="false"
      @order="workflow-step-name"
      @activeOrder={{@activeOrder}}
      @changeSort={{@changeSort}}
      @ascending={{@ascending}}
      @name="workflow-step-name"
    />
  
  */
  {
    "id": "CpIJPtLn",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@sortable\",\"@number\",\"@order\",\"@activeOrder\",\"@changeSort\",\"@ascending\",\"@name\"],[[30,1],\"false\",\"workflow-step-name\",[30,2],[30,3],[30,4],\"workflow-step-name\"]],null],[1,\"\\n\"]],[\"@sortable\",\"@activeOrder\",\"@changeSort\",\"@ascending\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/initializers/init-workflow.js",
    "scope": () => [_sortableColumn.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "init-workflow:workflowStepNameHeader"));
  const workflowStepNameCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="workflow-step-name">
      <WorkflowNameLink
        @topic_id={{@topic.id}}
        @workflow_name={{@topic.workflow_name}}
        @label={{@topic.workflow_step_name}}
      />
    </td>
  
  */
  {
    "id": "YW0foWBS",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"workflow-step-name\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic_id\",\"@workflow_name\",\"@label\"],[[30,1,[\"id\"]],[30,1,[\"workflow_name\"]],[30,1,[\"workflow_step_name\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-workflow/discourse/initializers/init-workflow.js",
    "scope": () => [_workflowNameLink.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "init-workflow:workflowStepNameCell"));
  var _default = _exports.default = {
    name: "discourse-workflow-initializer",
    initialize(container) {
      const router = container.lookup("service:router");
      const mobileView = container.lookup("service:site").mobileView;
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        api.addAdminPluginConfigurationNav("discourse-workflow", [{
          label: "admin.discourse_workflow.workflows.title",
          route: "adminPlugins.show.discourse-workflow-workflows"
        }]);
        api.addNavigationBarItem({
          name: "workflow",
          href: "/workflow"
        });
        api.registerValueTransformer("topic-list-item-class", _ref => {
          let {
            value
          } = _ref;
          if (WORKFLOW_LIST_ROUTES.includes(router.currentRouteName)) {
            value.push("workflow-list");
          }
          return value;
        });
        if (!mobileView) {
          api.registerValueTransformer("topic-list-columns", _ref2 => {
            let {
              value: columns
            } = _ref2;
            if (WORKFLOW_LIST_ROUTES.includes(router.currentRouteName)) {
              columns.add("workflow-name", {
                header: workflowNameHeader,
                item: workflowNameCell,
                after: "activity"
              });
            }
            return columns;
          });
          api.registerValueTransformer("topic-list-columns", _ref3 => {
            let {
              value: columns
            } = _ref3;
            if (WORKFLOW_LIST_ROUTES.includes(router.currentRouteName)) {
              columns.add("workflow-step-position", {
                header: workflowStepPositionHeader,
                item: workflowStepPositionCell,
                after: "workflow-name"
              });
            }
            return columns;
          });
          api.registerValueTransformer("topic-list-columns", _ref4 => {
            let {
              value: columns
            } = _ref4;
            if (WORKFLOW_LIST_ROUTES.includes(router.currentRouteName)) {
              columns.add("workflow-step-name", {
                header: workflowStepNameHeader,
                item: workflowStepNameCell,
                after: "workflow-step-position"
              });
            }
            return columns;
          });
        }
        api.addPostSmallActionIcon("workflow_transition", "right-left");
      });
    }
  };
});