define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-steps-edit", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowStepsEdit extends _discourse.default {
    async model(params) {
      const allWorkflowSteps = await this.modelFor("adminPlugins.show.discourse-workflow-workflows-steps");
      const id = parseInt(params.step_id, 10);
      const workflowStep = allWorkflowSteps.findBy("id", id);
      const workflowSteps = await this.store.findAll("workflow-step", {
        workflow_id: workflowStep.workflow_id
      });
      workflowStep.set("workflowSteps", workflowSteps.content);
      const workflow = await this.store.find("workflow", workflowStep.workflow_id);
      workflowStep.set("workflow", workflow);
      return workflowStep;
    }
    async setupController(controller, model) {
      super.setupController(controller, model);
      const workflowSteps = await this.store.findAll("workflow-step", {
        workflow_id: this.currentModel.workflow_id
      });
      controller.set("workflowSteps", workflowSteps.content);
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowStepsEdit;
});