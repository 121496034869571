define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowNew extends _discourse.default {
    async model() {
      const record = this.store.createRecord("workflow");
      return record;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("allWorkflows", this.modelFor("adminPlugins.show.discourse-workflow-workflows"));
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowNew;
});