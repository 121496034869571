define("discourse/plugins/discourse-workflow/discourse/admin/routes/admin-plugins-show-discourse-workflow-workflows-steps-options-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseWorkflowWorkflowStepOptionsNew extends _discourse.default {
    async model() {
      // Get the parent workflow step
      const workflowStep = this.modelFor("adminPlugins.show.discourse-workflow-workflows-steps-options");
      // Create a new workflow step record
      const record = this.store.createRecord("workflow-step-option", {
        workflow_step_id: workflowStep.id,
        position: workflowStep.workflow_step_options.length > 0 ? workflowStep.workflow_step_options[workflowStep.workflow_step_options.length - 1].position + 1 : 1
      });

      // Attach it to the parent workflow to current step
      record.set("workflowStep", workflowStep);
      return record;
    }
    async setupController(controller, model) {
      super.setupController(controller, model);
      const workflowOptions = await this.store.findAll("workflow-option");
      controller.set("workflowOptions", workflowOptions.content);
      const workflowSteps = await this.store.findAll("workflow-step", {
        workflow_id: this.currentModel.workflowStep.workflow_id
      });
      controller.set("workflowSteps", workflowSteps.content);
    }
  }
  _exports.default = AdminPluginsShowDiscourseWorkflowWorkflowStepOptionsNew;
});